import {
    isEmptyArray,
    isEmptyString,
    isNumeric,
    isEqualByIgnoringNull
} from './commonValidations'

export function filterRateCards({
    allRateCardsList,
    numTiers,
    startFee,
    tier2Fee,
    tier3Fee,
    tier4Fee
}) {
    // console.log(allRateCardsList)
    if (allRateCardsList && allRateCardsList.length > 0) {
        console.log(typeof numTiers)
        const primaryFilterRateCardList = allRateCardsList.filter(
            (rateCard) => {
                if (
                    numTiers &&
                    rateCard.priceTier.length === Number(numTiers) &&
                    startFee &&
                    rateCard.priceTier[0] &&
                    rateCard.priceTier[0].fee === Number(startFee)
                ) {
                    return true
                } else {
                    return false
                }
            }
        )
        console.log(primaryFilterRateCardList)

        const secondaryFilterRateCardList = primaryFilterRateCardList.filter(
            (rateCard) => {
                let retVal = true
                if (!isEmptyString(tier2Fee)) {
                    if (
                        rateCard.priceTier[1] &&
                        rateCard.priceTier[1].fee === Number(tier2Fee)
                    ) {
                        retVal = true

                        if (!isEmptyString(tier3Fee)) {
                            if (
                                rateCard.priceTier[2] &&
                                rateCard.priceTier[2].fee === Number(tier3Fee)
                            ) {
                                retVal = true

                                if (!isEmptyString(tier4Fee)) {
                                    if (
                                        rateCard.priceTier[3] &&
                                        rateCard.priceTier[3].fee ===
                                            Number(tier4Fee)
                                    ) {
                                        retVal = true
                                    } else {
                                        retVal = false
                                    }
                                }
                            } else {
                                retVal = false
                            }
                        }
                    } else {
                        retVal = false
                    }
                }
                return retVal
            }
        )

        return secondaryFilterRateCardList
    }
}

/**
 *
 * @returns {*[]}
 */
export function populateDollarPercentOptions() {
    const options = []
    options.push({ value: 'AMT', text: '$', label: 'Add' })
    options.push({ value: 'PCT', text: '%', label: 'Multiply' })
    return options
}

export function populateRateTypeOptions() {
    const options = []
    options.push({ label: 'Flat Fee', value: 'FlatFee', text: 'Flat Fee' })
    options.push({ label: 'Complex', value: 'Tiered', text: 'Complex' })
    return options
}

export function validateCreateRateCard(rateCardInfo) {
    let newErrorMessages = {}
    if (isEmptyString(rateCardInfo.rateCardDescription)) {
        newErrorMessages.rateCardDescription = 'Description is required'
    }

    if (!isEmptyArray(rateCardInfo.priceTiers)) {
        newErrorMessages.priceTiers = []
        rateCardInfo.priceTiers.forEach((priceTier, index) => {
            newErrorMessages.priceTiers[index] = null
            // check if highLimit is greater than lowlimit
            if (priceTier.highLimit <= priceTier.lowLimit) {
                newErrorMessages.priceTiers[index] = {
                    highLimit: 'High limit must be greater than Low limit'
                }
            }
            // check if values are numeric for highLimit and fee
            if (!isNumeric(priceTier.highLimit)) {
                newErrorMessages.priceTiers[index] = {
                    highLimit: 'High Limit must be numeric'
                }
            }
            if (!isNumeric(priceTier.fee)) {
                newErrorMessages.priceTiers[index] = {
                    fee: 'Fee must be numeric'
                }
            }
        })

        // check if any priceTiers has errors, else make if null
        if (
            newErrorMessages.priceTiers.filter((priceTier) => priceTier != null)
                .length === 0
        ) {
            delete newErrorMessages.priceTiers
        }
    }

    return newErrorMessages
}

export function generateRateCardDescription(feeTiers) {
    if (feeTiers?.length === 1) {
        return `Flat Fee $${feeTiers[0].fee}`
    } else if (feeTiers?.length > 1) {
        return `${feeTiers.length} Tiers $${feeTiers[0].fee}`
    }
    return '-'
}

export function getMatchingRateCards(allRateCardsList, newRateCardInfo) {
    // console.log('newRateCardInfo', newRateCardInfo)
    let matchedRateCardList = []

    // filter all the ratecards that match the number of tiers
    if (allRateCardsList && allRateCardsList.length > 0) {
        let tierMatchedRateCardList = allRateCardsList.filter((rateCard) => {
            return (
                rateCard.priceTier?.length ===
                newRateCardInfo.priceTiers?.length
            )
        })
        // console.log('tierMatchedRateCardList', tierMatchedRateCardList)
        // filter all the ratecards that match the start price
        let startPriceMatchedRateCardList = tierMatchedRateCardList.filter(
            (rateCard) => {
                // console.log('rateCard.priceTier', rateCard.priceTier)
                if (rateCard.priceTier && rateCard.priceTier.length > 0) {
                    return (
                        rateCard.priceTier[0].fee ==
                            newRateCardInfo.priceTiers[0].fee &&
                        rateCard.priceTier[0].highLimit ==
                            newRateCardInfo.priceTiers[0].highLimit
                    )
                }
            }
        )

        // console.log(
        //     'startPriceMatchedRateCardList',
        //     startPriceMatchedRateCardList
        // )

        // check if the remaining tiers and adders match
        startPriceMatchedRateCardList.forEach((rateCard) => {
            let matched = false
            let priceTierMatchList = []
            rateCard.priceTier.forEach((priceTier, index) => {
                if (
                    priceTier.fee == newRateCardInfo.priceTiers[index].fee &&
                    priceTier.highLimit ==
                        newRateCardInfo.priceTiers[index].highLimit
                ) {
                    priceTierMatchList.push(true)
                } else {
                    priceTierMatchList.push(false)
                }
            })

            // console.log('priceTierMatchList', priceTierMatchList)
            if (
                priceTierMatchList.filter(
                    (priceTierMatch) => priceTierMatch === true
                ).length == rateCard.priceTier?.length &&
                (isEqualByIgnoringNull(
                    rateCard.addFeesAmount,
                    newRateCardInfo.addFee,
                    'addFeesAmount',
                    rateCard.id
                ) ||
                    isEqualByIgnoringNull(
                        rateCard.feePercent,
                        newRateCardInfo.addFee,
                        'feePercent',
                        rateCard.id
                    )) &&
                isEqualByIgnoringNull(
                    rateCard.salesPriceLimit,
                    newRateCardInfo.addFeeAfterAmount,
                    'salesPriceLimit',
                    rateCard.id
                ) &&
                isEqualByIgnoringNull(
                    rateCard.feeUnit,
                    newRateCardInfo.addFeeForEveryAmount,
                    'feeUnit',
                    rateCard.id
                ) &&
                isEqualByIgnoringNull(
                    rateCard.feeMaximum,
                    newRateCardInfo.addFeeMaxAmount,
                    'feeMaximum',
                    rateCard.id
                )
            ) {
                matched = true
            }

            if (matched) {
                matchedRateCardList.push(rateCard)
            }
        })
        // console.log('matchedRateCardList', matchedRateCardList)
        return matchedRateCardList
    }

    return null
}
