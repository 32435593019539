import { Table } from '@interstate/components/Table'
import React, { useEffect, useState } from 'react'
import {
    getVehicleAuctionNotes,
    getVehicleAuctionSblu
} from '../../service/auctionVehicleDataService'
import { getPossibleFeeSchedules } from '../../service/feeScheduleService'
import { FeeStructureColumn } from '../FeeStructureColumn'
import { Grid } from '@interstate/components/Grid'

export function IndividualAudit({ data }) {
    const [vehicleData, setVehicleData] = useState({})
    const [vehicleNotes, setVehicleNotes] = useState([])
    const [feeSchedules, setFeeSchedules] = useState([])

    useEffect(() => {
        let av = data[0].buCode
        let sblu = data[0].sblu
        getVehicleAuctionSblu(av, sblu)
            .then((d) => {
                setVehicleData(d)
            })
            .catch((error) => {
                setVehicleData(error.response.data)
            })

        getVehicleAuctionNotes(av, data[0].workOrderNumber)
            .then((d) => {
                setVehicleNotes(d)
            })
            .catch((error) => {
                setVehicleNotes(error.response.data)
            })

        let customerIds = [
            data[0]?.seller,
            data[0]?.buyer,
            data[0]?.sellerGroup,
            data[0]?.buyerGroup,
            data[0]?.sellerSubGroup,
            data[0]?.buyerSubGroup
        ]

        getPossibleFeeSchedules(av, customerIds, data[0].saleType)
            .then((d) => {
                setFeeSchedules(d)
            })
            .catch((error) => {
                setFeeSchedules(error.response.data)
            })
    }, [data])

    return (
        <>
            <Grid container spacing={2}>
                <Grid xs={12} md={3}>
                    <div>
                        <b>Auction:</b>
                        {data[0].buCode}
                    </div>
                    <div>
                        <b>SBLU:</b> {data[0].sblu}
                    </div>
                    <div>
                        <b>Work Order:</b> {data[0].workOrderNumber}
                    </div>
                    <div>
                        <b>Universal Key:</b>
                        {data[0]?.saleYear}-{data[0]?.saleNumber}-
                        {data[0]?.lane}-{data[0]?.runNumber}
                    </div>
                </Grid>
                <Grid xs={12} md={3}>
                    {vehicleData?.body && (
                        <div>
                            <div>
                                <b>BUYER:</b> {vehicleData.body.buyer}
                            </div>
                            <div>
                                <b>BUYER Group:</b>{' '}
                                {vehicleData.body.buyerGroup}{' '}
                                {vehicleData.body.buyerSubGroup}
                            </div>
                            <div>
                                <b>BUYER L/T:</b> {vehicleData.body.lane} /{' '}
                                {vehicleData.body.buyerFeeTable}
                            </div>
                            <div>
                                <b>BUYER fee:</b> {vehicleData.body.buyerFee}
                            </div>
                        </div>
                    )}
                </Grid>
                <Grid xs={12} md={3}>
                    {vehicleData?.body && (
                        <div>
                            <div>
                                <b>SELLER:</b> {vehicleData.body.seller}
                            </div>
                            <div>
                                <b>SELLER Group:</b>{' '}
                                {vehicleData.body.sellerGroup}{' '}
                                {vehicleData.body.sellerSubGroup}{' '}
                            </div>
                            <div>
                                <b>SELLER L/T:</b> {vehicleData.body.lane} /{' '}
                                {vehicleData.body.sellerFeeTable}
                            </div>
                            <div>
                                <b>SELLER fee:</b> {vehicleData.body.sellerFee}
                            </div>
                        </div>
                    )}
                </Grid>
                <Grid xs={12} md={3}>
                    {vehicleData?.body && (
                        <div>
                            <div>
                                <b>VIN:</b> {vehicleData.body.vin}
                            </div>
                            <div>
                                <b>Sale Price:</b> {vehicleData.body.salePrice}
                            </div>
                            <div>
                                <b>Sale Date:</b> {vehicleData.body.saleDate}
                            </div>
                        </div>
                    )}
                </Grid>
            </Grid>
            <div>
                <h2>Calculated Fees</h2>
                <Table
                    tableLayout={'auto'}
                    columns={[
                        {
                            title: 'Fee Category',
                            render: (item) => <span>{item.feeCategory}</span>
                        },
                        {
                            title: 'Fee Schedule Type',
                            render: (item) => <span>{item.contractType}</span>
                        },
                        {
                            title: 'Fee Type',
                            render: (item) => <span>{item.feeType}</span>
                        },
                        {
                            title: 'Pat Fee',
                            render: (item) => (
                                <span>{item.patCalculatedFee}</span>
                            )
                        },
                        {
                            title: 'AS400 Fee',
                            render: (item) => (
                                <span>{item.as400CalculatedFee}</span>
                            )
                        },
                        {
                            title: 'FeeID',
                            render: (item) => (
                                <span>{item.feeSchedulePartId}</span>
                            )
                        }
                    ]}
                    data={data}
                />

                <div>
                    <h2>Possible Fees</h2>
                    <Table
                        tableLayout={'auto'}
                        enablePagination={false}
                        columns={[
                            {
                                title: 'Fee Schedule Type',
                                render: (item) => (
                                    <span>{item.contractType}</span>
                                )
                            },
                            {
                                title: 'Sale Type',
                                render: (item) => <span>{item.saleType}</span>
                            },
                            {
                                title: 'Fee Type',
                                render: (item) => <span>{item.feeType}</span>
                            },
                            {
                                title: 'Customer',
                                render: (item) => <span>{item.customer}</span>
                            },
                            {
                                title: 'Fee Structure',
                                render: (item) => (
                                    <span>
                                        <FeeStructureColumn
                                            feeStructure={item.feeStructure}
                                        />
                                    </span>
                                )
                            },
                            {
                                title: 'Description',
                                render: (item) => (
                                    <span>{item.description}</span>
                                )
                            },
                            {
                                title: 'Start Date',
                                render: (item) => <span>{item.startDate}</span>
                            },
                            {
                                title: 'End Date',
                                render: (item) => <span>{item.endDate}</span>
                            },
                            {
                                title: 'FeeID',
                                render: (item) => <span>{item.id}</span>
                            }
                        ]}
                        data={feeSchedules}
                    />
                </div>

                <div>
                    <h2>Vehicle Notes</h2>
                    <Table
                        tableLayout={'auto'}
                        enablePagination={false}
                        columns={[
                            {
                                title: 'Note',
                                render: (item) => <span>{item.notesText}</span>
                            },
                            {
                                title: 'Note Type',
                                render: (item) => <span>{item.addedBy}</span>
                            },
                            {
                                title: 'Note Date',
                                render: (item) => <span>{item.addedDate}</span>
                            }
                        ]}
                        data={vehicleNotes['vehicleNotes_ByWO_R']}
                    />
                </div>
            </div>
        </>
    )
}
