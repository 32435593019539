import React, { useMemo } from 'react'
import { auctionDisplayWithCode } from '../../utils/auction.util'
import { DisplayFlatTieredDescription } from './DisplayFlatTieredDescription'
import { DisplayFeeTierAmount } from './DisplayFeeTierAmount'
import { renderDateFromIsoString } from '../../utils/Formatters'
import { Table } from '@interstate/components/Table'
import { useStaticDataContext } from '../../context/StaticDataContext'
import { Popover } from '@interstate/components/Popover'
import { PAT_ROLE } from '../../resources/enum.constants'
import { SearchResultsActionPopover } from './SearchResultsActionPopover'
import { useAuth } from '../../auth/AuthProvider'

function SearchResultsTable({
    searchResultsTableData,
    showExpireFeeSchedule,
    showDeleteFeeSchedule,
    editFeeSchedule,
    copyFeeSchedule
}) {
    const authService = useAuth()
    const staticData = useStaticDataContext()
    const principal = authService?.principal

    function displayCreatedBy(feeScheduleFlat) {
        return (
            <Popover
                onOpen={function Ua() {}}
                popoverContent={renderDateFromIsoString(
                    feeScheduleFlat.createdTime,
                    'MM/DD/YYYY h:mm:ss a'
                )}
                trigger="hover">
                {feeScheduleFlat.createdBy}
            </Popover>
        )
    }

    function displayActionOptions(feeScheduleFlat) {
        let userRole = principal?.role
        if (userRole === PAT_ROLE.VIEW_ROLE) {
            return <></>
        } else if (
            (PAT_ROLE.PRICING_EDIT === userRole ||
                PAT_ROLE.AUCTION_EDIT == userRole) &&
            feeScheduleFlat.createdByRole !== userRole
        ) {
            return <></>
        }
        return (
            <SearchResultsActionPopover
                feeScheduleFlat={feeScheduleFlat}
                showExpireFeeSchedule={showExpireFeeSchedule}
                showDeleteFeeSchedule={showDeleteFeeSchedule}
                copyFeeSchedule={copyFeeSchedule}
                editFeeSchedule={editFeeSchedule}
                userRole={userRole}
            />
        )
    }

    return (
        <div className="searchResults">
            <Table
                columns={[
                    {
                        className: 'column-scheduleType',
                        dataIndex: 'contractType',
                        title: 'Fee Schedule Type'
                    },
                    {
                        className: 'column-auctionCode',
                        render: (item) =>
                            auctionDisplayWithCode(
                                item.buCode,
                                staticData.auctionInfoMap
                            ),
                        title: 'Location'
                    },
                    {
                        className: 'column-saleType',
                        dataIndex: 'saleType',
                        title: 'Sale type'
                    },
                    {
                        className: 'column-description',
                        dataIndex: 'description',
                        title: 'Description'
                    },
                    {
                        className: 'column-customer',
                        dataIndex: 'customer',
                        title: 'Customer'
                    },
                    {
                        className: 'column-feeType',
                        dataIndex: 'feeType',
                        title: 'Fee type'
                    },
                    {
                        className: 'column-flatTiered',
                        render: (item) => (
                            <div>
                                <DisplayFlatTieredDescription
                                    feeScheduleFlat={
                                        item
                                    }></DisplayFlatTieredDescription>
                            </div>
                        ),
                        title: 'Flat/Tiered'
                    },
                    {
                        className: 'column-Amount',
                        render: (item) => (
                            <div>
                                <DisplayFeeTierAmount
                                    feeScheduleFlat={
                                        item
                                    }></DisplayFeeTierAmount>
                            </div>
                        ),
                        title: 'Amount/Tiers'
                    },
                    {
                        className: 'column-startDate',
                        dataIndex: 'startDate',
                        render: (item) => (
                            <div>
                                {renderDateFromIsoString(item, 'MM/DD/YYYY')}
                            </div>
                        ),
                        title: 'Start date'
                    },
                    {
                        className: 'column-endDate',
                        dataIndex: 'endDate',
                        render: (item) => (
                            <div>
                                {renderDateFromIsoString(item, 'MM/DD/YYYY')}
                            </div>
                        ),
                        title: 'End date'
                    },
                    {
                        className: 'column-createdBy',
                        render: (item) => displayCreatedBy(item),
                        title: 'Created by'
                    },
                    {
                        className: 'column-action',
                        render: (item) => displayActionOptions(item),
                        title: ''
                    }
                ]}
                data={searchResultsTableData}
                tableLayout={'auto'}
                enablePagination={true}
                id="overlappingFeesTable"
                defaultPageSize={50}
                onChangePage={function Ua() {}}
                onChangePageSize={function Ua() {}}
                onChangeSelectedRows={function Ua() {}}
                onChangeSorter={function Ua() {}}
                onExpandClick={function Ua() {}}
                onRowCallback={function Ua() {}}
                onRowSelect={function Ua() {}}
            />
        </div>
    )
}

export default React.memo(SearchResultsTable)
