import React from 'react'
import { isDateAfterToday } from '../../utils/date.helper.util'

export function ActionableFeeInfo({
    feeScheduleFlat,
    deleteMode = false,
    expireMode = false
}) {
    return (
        <div>
            {deleteMode && <h3>Fee Schedule will be deleted</h3>}
            {expireMode && <h3>Fee Schedule will be set to expire today</h3>}
            <p>Schedule Type: {feeScheduleFlat.contractType}</p>
            <p>Auction: {feeScheduleFlat.buCode}</p>
            <p>Customer: {feeScheduleFlat.customer}</p>
            <p>Sale Type: {feeScheduleFlat.saleType}</p>
            <p>Fee Type: {feeScheduleFlat.feeType}</p>
        </div>
    )
}
