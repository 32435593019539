const accountRegex = /^\d{7}$/
const groupCodeRegex = /^[a-zA-Z0-9]{3,4}$/
const altGroupCodeRegex = /^[a-zA-Z0-9\s]{6}A$/
export function getCustomerTypeFromValue(value) {
    if (!value || value.length === 0) {
        return null
    }
    if (accountRegex.test(value)) {
        return 'accountNumber'
    } else if (groupCodeRegex.test(value)) {
        return 'groupCode'
    } else if (altGroupCodeRegex.test(value)) {
        return 'altGroupCode'
    }
    return null
}

export function trimAltGroupCode(value) {
    if (value && value.length > 3) {
        return value.replace(' A', '').trim()
        return value
    }
    return value
}

export function padAltGroupCode(value) {
    if (value && value.length < 6) {
        return value.padEnd(6, ' ') + 'A'
    }
    return value
}
