import { React, useEffect, useState, useCallback } from 'react'
import { PrimaryActionButton } from '../InputControls'
import {
    createSearchParams,
    useNavigate,
    useSearchParams
} from 'react-router-dom'
import { Grid } from '@interstate/components/Grid'
import { FeeScheduleTypeSelector } from '../FeeScheduleTypeSelector'
import { LocationComboBox } from '../common/LocationComboBox'
import { FeeTypesComboBox } from '../common/FeeTypesComboBox'
import { SaleTypesComboBox } from '../common/SaleTypesComboBox'
import { PatDatePicker } from '../common/PatDatePicker'
import { TextArea } from '@interstate/components/TextArea'
import { CheckBox } from '@interstate/components/CheckBox'
import { Badge } from '@interstate/components/Badge'
import { useStaticDataContext } from '../../context/StaticDataContext'
import { useAuth } from '../../auth/AuthProvider'
import { auctionOptionsUtil } from '../../utils/auction.util'
import { validateSearchFields } from '../search/validateSearchFields'

export function SearchFees({ text, ...props }) {
    const navigate = useNavigate()
    const [queryParams] = useSearchParams()

    const [errors, setErrors] = useState()
    const [feeScheduleType, setFeeScheduleType] = useState()
    const [selectedLocations, setSelectedLocations] = useState()
    const [feeType, setFeeType] = useState()
    const [saleType, setSaleType] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [description, setDescription] = useState()
    const [accounts, setAccounts] = useState([])
    const [groupCodes, setGroupCodes] = useState([])
    const [altGroupCodes, setAltGroupCodes] = useState([])
    const [includeDeletedExpired, setIncludeDeletedExpired] = useState(false)
    const [includeSaleTypeParent, setIncludeSaleTypeParent] = useState(false)
    const [anyCustomersOnly, setAnyCustomersOnly] = useState(false)
    const [noCustomersOnly, setNoCustomersOnly] = useState(false)

    const staticDataContext = useStaticDataContext()
    const authService = useAuth()
    const auctionOptions = auctionOptionsUtil(
        staticDataContext,
        true,
        authService.principal
    )

    useEffect(() => {
        if (queryParams) {
            ifValuePresent(queryParams.get('feeScheduleTypes'), () =>
                setFeeScheduleType(queryParams.get('feeScheduleTypes'))
            )
            ifValuePresent(queryParams.get('accounts'), () =>
                setAccounts(queryParams.get('accounts')?.split(','))
            )
            ifValuePresent(queryParams.get('groupCodes'), () =>
                setGroupCodes(queryParams.get('groupCodes')?.split(','))
            )
            ifValuePresent(queryParams.get('altGroupCodes'), () =>
                setAltGroupCodes(queryParams.get('altGroupCodes')?.split(','))
            )
            // setSelectedLocations(queryParams.get('selectedLocations'))
            ifValuePresent(queryParams.get('feeTypes'), () =>
                setFeeType(queryParams.get('feeTypes'))
            )
            ifValuePresent(queryParams.get('saleTypes'), () =>
                setSaleType(queryParams.get('saleTypes'))
            )
            ifValuePresent(queryParams.get('startDate'), () =>
                setStartDate(queryParams.get('startDate'))
            )
            ifValuePresent(queryParams.get('endDate'), () =>
                setEndDate(queryParams.get('endDate'))
            )
            ifValuePresent(queryParams.get('description'), () =>
                setDescription(queryParams.get('description'))
            )
            ifValuePresent(queryParams.get('includeDeletedExpired'), () => {
                setIncludeDeletedExpired(
                    queryParams.get('includeDeletedExpired') === 'true'
                )
            })
            ifValuePresent(queryParams.get('includeSaleTypeParent'), () => {
                setIncludeSaleTypeParent(
                    queryParams.get('includeSaleTypeParent') === 'true'
                )
            })
            ifValuePresent(queryParams.get('auctionCodes'), () => {
                let auctionCodesList = queryParams
                    .get('auctionCodes')
                    ?.split(',')
                if (auctionCodesList?.length > 0) {
                    let newSelectedAuctionOptions = []
                    auctionCodesList.forEach((auctionCode) => {
                        let auctionOption = auctionOptions.find(
                            (auctionOption) =>
                                auctionOption.value === auctionCode
                        )
                        if (auctionOption) {
                            newSelectedAuctionOptions.push(auctionOption)
                        }
                    })

                    setSelectedLocations(newSelectedAuctionOptions)
                }
            })
            if (
                !queryParams.get('auctionCodes') ||
                queryParams.get('auctionCodes')?.length == 0
            ) {
                setSelectedLocations(['ALL'])
            }
        }
    }, [queryParams])

    function ifValuePresent(value, callback) {
        if (value && value.length > 0) {
            callback()
        }
    }

    const divCheckboxStyle = {
        position: 'relative',
        height: '100%'
    }
    const checkboxStyle = {
        '@media only screen and (min-width: 600px)': {
            margin: 0,
            position: 'absolute',
            top: '50%',
            '-ms-transform': 'translateY(-20%)',
            transform: 'translateY(-20%)'
        }
    }

    function performSearch() {
        const newErrors = validateSearchFields({
            feeScheduleType,
            selectedLocations,
            feeType,
            saleType,
            startDate,
            endDate,
            accounts,
            groupCodes,
            altGroupCodes,
            description
        })

        if (!newErrors) {
            const newQueryParams = {
                includeSaleTypeParent,
                includeDeletedExpired,
                anyCustomersOnly,
                noCustomersOnly
            }
            ifValuePresent(selectedLocations, () => {
                let commaSeperatedAuctions = selectedLocations
                    ?.map((selectedLocation) => selectedLocation.value)
                    ?.join(',')
                newQueryParams.auctionCodes = commaSeperatedAuctions
            })
            ifValuePresent(
                feeScheduleType,
                () => (newQueryParams.feeScheduleTypes = feeScheduleType)
            )
            ifValuePresent(
                accounts,
                () => (newQueryParams.accounts = accounts?.join(','))
            )
            ifValuePresent(
                groupCodes,
                () => (newQueryParams.groupCodes = groupCodes?.join(','))
            )
            ifValuePresent(
                altGroupCodes,
                () => (newQueryParams.altGroupCodes = altGroupCodes?.join())
            )
            ifValuePresent(feeType, () => (newQueryParams.feeTypes = feeType))
            ifValuePresent(
                saleType,
                () => (newQueryParams.saleTypes = saleType)
            )
            ifValuePresent(
                startDate,
                () => (newQueryParams.startDate = startDate)
            )
            ifValuePresent(endDate, () => (newQueryParams.endDate = endDate))
            ifValuePresent(
                description,
                () => (newQueryParams.description = description)
            )

            setErrors(undefined)
            navigate({
                pathname: '/boc/searchFeeResults',
                search: `?${createSearchParams(newQueryParams)}`
            })
        } else {
            setErrors(newErrors)
        }
    }

    function onInputChange(event) {
        switch (event.target.name) {
            case 'feeScheduleType':
                setFeeScheduleType(event.target.value)
                break
            case 'saleType':
                setSaleType(event.target.value)
                break
            case 'feeType':
                setFeeType(event.target.value)
                break
            case 'startDate':
                setStartDate(event.target.value?.additionalFormats?.iso)
                break
            case 'endDate':
                setEndDate(event.target.value?.additionalFormats?.iso)
                break
            case 'description':
                setDescription(event.target.value?.trim())
                break
            case 'accounts':
                setAccounts(event.target.value.replace(' ', '').split(','))
                break
            case 'groupCodes':
                setGroupCodes(event.target.value.replace(' ', '').split(','))
                break
            case 'altGroupCodes':
                setAltGroupCodes(event.target.value.replace(' ', '').split(','))
                break
            case 'includeDeletedExpired':
                if (event.target.value.checked) {
                    setIncludeDeletedExpired(true)
                } else {
                    setIncludeDeletedExpired(false)
                }
                break
            case 'includeSaleTypeParent':
                if (event.target.value.checked) {
                    setIncludeSaleTypeParent(true)
                } else {
                    setIncludeSaleTypeParent(false)
                }
                break
            case 'anyCustomersOnly':
                if (event.target.value.checked) {
                    setAnyCustomersOnly(true)
                } else {
                    setAnyCustomersOnly(false)
                }
                break
            case 'noCustomersOnly':
                if (event.target.value.checked) {
                    setNoCustomersOnly(true)
                } else {
                    setNoCustomersOnly(false)
                }
            default:
                break
        }
    }

    function onLocationsChange(event) {
        setSelectedLocations(event.target.value)
    }

    function onKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault()
            performSearch()
        }
    }

    function endDateSetter() {
        if (startDate) {
            let value = new Date(startDate?.additionalFormats?.iso || startDate)
            value.setDate(value.getDate())
            return value
        }
        return null
    }

    function handleKeyDown(event) {
        switch (event.keyCode) {
            case 13:
                performSearch()
                break
            default:
                break
        }
    }

    return (
        <div onKeyDown={handleKeyDown}>
            {errors && errors.searchText && (
                <Badge variant={'error'}>{errors.searchText}</Badge>
            )}
            <Grid container spacing={2}>
                <Grid xs={12} md={4}>
                    <div>
                        <FeeScheduleTypeSelector
                            value={feeScheduleType}
                            name="feeScheduleType"
                            onChange={onInputChange}
                            moduleID="FeeScheduleType"
                            showAllOptions={true}
                            includeAllSelection={true}
                            includeUnknownSelection={true}
                        />
                    </div>
                </Grid>
                <Grid xs={12} md={4}>
                    <LocationComboBox
                        all
                        value={selectedLocations}
                        multiple={true}
                        onChange={onLocationsChange}
                        auctionEditFilter={true}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid xs={12} md={4}>
                    <FeeTypesComboBox
                        name={'feeType'}
                        value={feeType}
                        onChange={onInputChange}
                        scheduleFeeType={feeScheduleType}
                        dataTestId={'FeeType_stflI'}
                        includeAllSelection={true}
                    />
                </Grid>

                <Grid xs={12} md={4}>
                    <div>
                        <SaleTypesComboBox
                            name="saleType"
                            value={saleType}
                            onChange={onInputChange}
                            data-testid={'SaleType_stflI'}
                            includeAllSelection={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div style={divCheckboxStyle}>
                        <CheckBox
                            checked={includeSaleTypeParent}
                            label="Include Sale Type Parents"
                            className=""
                            data-testid=""
                            name="includeSaleTypeParent"
                            onChange={onInputChange}
                            sx={checkboxStyle}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    md={4}
                    data-testid="container_DatePicker_StartDate">
                    <PatDatePicker
                        name="startDate"
                        label="Start Date"
                        data-testid="datePicker_StartDate"
                        onChange={onInputChange}
                        value={new Date(startDate)}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    data-testid="container_DatePicker_EndDate">
                    <PatDatePicker
                        name="endDate"
                        label="End Date"
                        data-testid="datePicker_EndDate"
                        onChange={onInputChange}
                        minDate={endDateSetter()}
                        value={new Date(endDate)}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <div style={divCheckboxStyle}>
                        <CheckBox
                            checked={includeDeletedExpired}
                            label="Include Expired"
                            className=""
                            data-testid=""
                            name="includeDeletedExpired"
                            onChange={onInputChange}
                            sx={checkboxStyle}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} data-testid="container_Description">
                    <TextArea
                        name="description"
                        label="Description"
                        data-testid="textarea_Description"
                        rows="3"
                        cols="100"
                        onChange={onInputChange}
                        value={description}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TextArea
                        name="accounts"
                        label="Accounts"
                        data-testid="textarea_Accounts"
                        rows="3"
                        cols="100"
                        onChange={onInputChange}
                        value={(accounts || []).join(', ')}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextArea
                        name="groupCodes"
                        label="Group Codes"
                        data-testid="textarea_GroupCodes"
                        rows="3"
                        cols="100"
                        onChange={onInputChange}
                        value={(groupCodes || []).join(', ')}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextArea
                        name="altGroupCodes"
                        label="Alternate Group Codes"
                        data-testid="textarea_AlternateGroupCodes"
                        rows="3"
                        cols="100"
                        onChange={onInputChange}
                        value={(altGroupCodes || []).join(', ')}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <div>
                        <CheckBox
                            checked={anyCustomersOnly}
                            label="Any Customer"
                            className=""
                            data-testid=""
                            name="anyCustomersOnly"
                            onChange={onInputChange}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div>
                        <CheckBox
                            checked={noCustomersOnly}
                            label="No Customer"
                            className=""
                            data-testid=""
                            name="noCustomersOnly"
                            onChange={onInputChange}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <PrimaryActionButton
                    name="btnSearch"
                    label="Search"
                    onClick={performSearch}></PrimaryActionButton>
            </Grid>
        </div>
    )
}
