import React from 'react'
import { Modal } from '@interstate/components/Modal'
import { PatDatePicker } from '../common/PatDatePicker'
import { Grid } from '@interstate/components/Grid'
import { useAuth } from '../../auth/AuthProvider'
import { TextArea } from '@interstate/components/TextArea'
import { debounce } from 'lodash'
import { PAT_ROLE } from '../../resources/enum.constants'
import {
    addDaysToToday,
    isDateAfterToday,
    isDateBeforeToday
} from '../../utils/date.helper.util'

export function EditEndDatePopupModal({
    showEditEndDatePopupModal,
    setShowEditEndDatePopupModal,
    actionableFeeScheduleFlat,
    saveEditFeeSchedule
}) {
    const authService = useAuth()

    function getMinEndDate() {
        if (
            isDateBeforeToday(
                actionableFeeScheduleFlat?.startDate,
                'YYYY-MM-DD'
            )
        ) {
            if (authService.principal?.role === PAT_ROLE.BOC_EDIT) {
                return addDaysToToday(-1)
            }
        }
        return new Date()
    }

    function getMaxEndDate() {
        if (authService.principal?.role === PAT_ROLE.AUCTION_EDIT) {
            return 14
        }
        return null
    }

    function handleInputChange(event) {
        switch (event.target.name) {
            case 'endDate':
                actionableFeeScheduleFlat.endDate =
                    event.target.value?.additionalFormats?.iso
                break
            case 'description':
                actionableFeeScheduleFlat.description = event.target.value
                break
            default:
                break
        }
    }

    return (
        <Modal
            size="small"
            show={showEditEndDatePopupModal}
            header={<div>Edit Fee Schedule</div>}
            footer={{
                primary: {
                    label: 'Confirm',
                    action: () => {
                        saveEditFeeSchedule(actionableFeeScheduleFlat)
                    },
                    datatestid: 'button_EditFeeSchedule_Confirm'
                },
                options: [
                    {
                        label: 'Cancel',
                        action: () => {
                            setShowEditEndDatePopupModal(false)
                        },
                        datatestid: 'button_EditFeeSchedule_Cancel'
                    }
                ]
            }}>
            <div>
                <Grid item xs={12} data-testid="container_DatePicker_EndDate">
                    <PatDatePicker
                        name="endDate"
                        label="End Date"
                        data-testid="datePicker_EndDate"
                        minDate={getMinEndDate()}
                        maxDays={getMaxEndDate()}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} data-testid="container_TextArea_Description">
                    {/* For now, we're limiting description to 30 chars, because that's the AS400 limit. The new backend allows 128. */}
                    <TextArea
                        name="description"
                        label="Description"
                        data-testid="textarea_Description"
                        rows="3"
                        cols="100"
                        onChange={debounce(handleInputChange, 500)}
                        value={actionableFeeScheduleFlat?.description}
                    />
                </Grid>
            </div>
        </Modal>
    )
}
