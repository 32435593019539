import React from 'react'
import { Tooltip } from '../ToolTip'
import { RateCardOnTooltip } from '../ratecard/RateCardOnTooltip'
import { FEE_FLAT_TIERED } from '../../resources/enum.constants'
import { InfoIcon } from '../Icons'
import { dollarsFull } from '../../utils/Formatters'

export function DisplayFlatTieredDescription({ feeScheduleFlat, saleTypeFee }) {
    let feeStructure = null
    if (feeScheduleFlat) {
        feeStructure = feeScheduleFlat.feeStructure
    } else if (saleTypeFee) {
        feeStructure = saleTypeFee.feeStructure
    }

    function displayFlatTiered() {
        if (feeStructure && feeStructure.feeStructureType) {
            if (
                feeStructure.feeStructureType.toUpperCase() ==
                FEE_FLAT_TIERED.FLAT.toUpperCase()
            ) {
                return <div>{FEE_FLAT_TIERED.FLAT}</div>
            } else if (
                feeStructure.feeStructureType.toUpperCase() ==
                FEE_FLAT_TIERED.TIERED.toUpperCase()
            ) {
                return <div>{FEE_FLAT_TIERED.TIERED}</div>
            }
        }
        return null
    }

    return <>{displayFlatTiered()}</>
}
